<!--单方采购入库-->
<template>
    <div class="SelfIn">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <ef-dept-type-and-dept-select
                        dept-label="进货机构"
                        @selectDeptType="setDeptType"
                        @selectDept="setDept"
                    />
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="审核状态">
                            <el-select placeholder="请选择" v-model="form.reviewStatus">
                                <el-option
                                    v-for="e in meta.reviewEnums"
                                    :label="e.label"
                                    :value="e.code"
                                    :key="e.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据状态">
                            <el-select placeholder="请选择" v-model="form.stockStatus">
                                <el-option v-for="e in meta.inEnums" :label="e.label" :value="e.code" :key="e.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.purchase.selfIn.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.purchase.selfIn.create')"
                >新建</el-button
            >
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.purchase.selfIn.review')"
                >审核</el-button
            >
            <el-button type="primary" @click="handleIn" size="small" v-if="hasPrivilege('menu.purchase.selfIn.stock')"
                >入库</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.purchase.selfIn.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/purchase/selfIn" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="240" prop="code" v-if="showColumn('code')" />
                <el-table-column label="进货机构" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="单据金额"
                    width="140"
                    prop="bizMoney"
                    key="bizMoney"
                    v-if="showColumn('bizMoney') && showInPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row | total }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核状态"
                    width="140"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="单据状态" width="160" prop="status" key="status" v-if="showColumn('status')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockInStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库时间"
                    width="160"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.purchase.selfIn.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.purchase.selfIn.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.purchase.selfIn.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';
import Decimal from 'decimal';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfReviewBiz from 'components/EfReviewBiz';
import { DeptTypeEnum } from 'js/DeptCommon';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'SelfIn',
    components: { EfReviewBiz, EfEndDate, EfStartDate, EfDeptTypeAndDeptSelect, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                code: '',
                deptType: '',
                deptCode: '',
                reviewStatus: '',
                stockStatus: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                delete: '/purchase/selfIn/delete',
                page: '/purchase/selfIn/page',
            },
            meta: {
                reviewEnums: Util.reviewStatusEnum(true),
                inEnums: Util.stockInStatusEnum(true),
            },
        };
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleDelete(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
        handleIn(row) {
            if (row.code) {
                this.codes = row.code;
                if (row.reviewStatus != 2) {
                    this.$message.error('请选择已审核商品行列');
                } else if (row.stockStatus != 0) {
                    this.$message.error('商品仅能入库一次');
                } else {
                    this.open();
                }
            } else if (this.$refs['table'].selection.length != 1) {
                this.$message.error('选择单据数量必须为一条');
            } else if (this.$refs['table'].selection[0].reviewStatus != 2) {
                this.$message.error('请勾选已审核商品');
            } else if (this.$refs['table'].selection[0].stockStatus != 0) {
                this.$message.error('商品仅能入库一次');
            } else {
                this.open();
            }
        },
        open() {
            this.$confirm('确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.in();
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消入库',
                    });
                });
        },
        in() {
            let _params = {};
            if (this.$refs['table'].selection[0]) {
                _params = {
                    codes: this.$refs['table'].selection[0].code,
                };
            } else {
                _params = {
                    codes: this.codes,
                };
            }

            UrlUtils.PostRemote(this, '/purchase/selfIn/in', _params, null, () => {
                this.handleQuery();
                this.$message.success('操作成功');
            });
        },

        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.purchase.selfIn.create', ['采购管理', '单方采购入库', '新建采购']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.purchase.selfIn.detail', ['采购管理', '单方采购入库', '入库详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能编辑');
            } else {
                Util.nameJump(this, 'menu.purchase.selfIn.edit', ['采购管理', '单方采购入库', '编辑入库'], {
                    form: row,
                    code: row.code,
                });
            }
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '单方采购入库', '/purchase/selfIn/export', this.form, codes);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
    },
    filters: {
        total(row) {
            let nowTotalMoney = 0;
            row.counts.forEach((v, i) => {
                const temMoney = Decimal(v).mul(Decimal(row.prices[i])).toNumber();
                nowTotalMoney = Decimal(nowTotalMoney).add(temMoney).toNumber();
            });
            if (row.deptType == DeptTypeEnum.REPOSITORY) {
                return MoneyUtils.moneyToThree(nowTotalMoney);
            } else {
                return MoneyUtils.moneyToYuan(nowTotalMoney);
            }
        },
    },
};
</script>

<style scoped>
.SelfIn .el-form-item {
    margin-bottom: 0;
}
</style>
